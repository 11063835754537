import * as d3 from 'd3-shape';
import { useMemo } from 'react';
import { ShapeFree } from '../../tools';
import { fadeColor } from '../../utils/const';
import { pointsToTuples } from '../../utils/point-to-tuples-reducer';
import { when } from '../../utils/when';
import { Marker } from './marker';

// var curveTypes = [
// 	{name: 'curveBasis', curve: d3.curveBasis, active: true, lineString: '', clear: true, info: 'Interpolates the start and end points and approximates the inner points using a B-spline.'},
// 	{name: 'curveBundle (ß=1)', curve: d3.curveBundle.beta(1), active: false, lineString: '', clear: false, info: 'Same as curveBasis with the addition of a paramter ß which determines how close to a straight line the curve is. If ß=1 the curve is the same as curveBasis.'},
// 	{name: 'curveCardinal (tension=0)', curve: d3.curveCardinal.tension(0), active: false, lineString: '', clear: true, info: "Interpolates the points using a cubic B-spline. A tension parameter determines how 'taut' the curve is. As tension approaches 1 the segments become linear."},
//     {name: 'curveCatmullRom (α=0)', curve: d3.curveCatmullRom.alpha(0), active: false, lineString: '', clear: true, info: 'Similar to curveCardinal (tension=0) but with a parameter α that determines the parameterisation used to interpolate the points. If α=0 the parameterisation is uniform.'},
// 	{name: 'curveCatmullRom (α=0.5)', curve: d3.curveCatmullRom.alpha(0.5), active: false, lineString: '', clear: false, info: 'Similar to curveCardinal (tension=0) but with a parameter α that determines the parameterisation used to interpolate the points. If α=0.5 the parameterisation is centripetal and self intersecting loops are avoided.'},
// 	{name: 'curveCatmullRom (α=1)', curve: d3.curveCatmullRom.alpha(1), active: false, lineString: '', clear: false, info: 'Similar to curveCardinal (tension=0) but with a parameter α that determines the parameterisation used to interpolate the points. If α=1 the parameterisation is chordal.'},
// 	{name: 'curveNatural', curve: d3.curveNatural, active: false, lineString: '', clear: true, info: 'Interpolates the points with a cubic spline with zero 2nd derivatives at the endpoints.'},
// ];

const line = d3.line();
const smoothedLine = line //.curve(d3.curveNatural);
export const Free = ({ shape, live, faded }: { shape: ShapeFree, live: boolean, faded?: boolean }) => {

    const pathD = useMemo(() => (live ? line : smoothedLine)(shape.points.reduce(pointsToTuples, [])), [shape, live]);

    const markerStart = ['start', 'both'].includes(shape.tool.arrowType || '');
    const markerEnd = ['end', 'both'].includes(shape.tool.arrowType || '');

    const color = faded ? fadeColor : shape.tool.lineColor;

    return <>
        {when(!!shape.tool.arrowType, <Marker color={shape.tool.lineColor} id={`marker-${shape.id}`} />)}
        <path
            d={pathD!}
            strokeWidth={shape.tool.lineWidth}
            strokeLinecap='round'
            strokeLinejoin='round'
            fill='none'
            stroke={live ? color : color}
            // strokeDasharray={live ? lineWidth*2 : 0}
            markerStart={when(markerStart, `url(#marker-${shape.id})`)}
            markerEnd={when(markerEnd, `url(#marker-${shape.id})`)}
        />
    </>
}
